<template>
  <div :class="{container:true,notScroll:isScroll}">
       <!-- 顶部 -->
    <MoTitle :showLeft="false" title="新闻动态" @moTitltFn="navPanel"></MoTitle>
    <div class="moNewTextContainer">
      <!--  -->
      <div class="center" v-if="data">
        <h2 class="h2Desc" style="text-align:center;margin:20px 0">{{data.NEWS_TITLE}}</h2>
        <div v-html="data.NEWS_CONTENT" class="moNewsContent">{{data.NEWS_CONTENT}}</div>
        <span class="time" style="display:block;text-align:right;width:100%">
          {{data.NEWS_TIME?data.NEWS_TIME.split(' ')[0]:''}}
        </span>
      </div>
    </div>
    <MoBottom></MoBottom>
      <MoFoot></MoFoot>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import { findNews } from '@/require/index.js'
export default {
  name: 'moNewText',
  components: {
    MoTitle
  },
  data () {
    return {
      isScroll: false,
      data: null
    }
  },
  methods: {
    navPanel (v) {
      this.isScroll = v
    },
    getNew (id) {
      findNews(id).then(res => {
        if (res && res.data) {
          this.data = { ...res.data }
        }
      })
    },
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push({ path: '/news/newContent', query: { id: this.$route.query.id } })
      }
    }
  },
  created () {
    this.getNew(this.$route.query.id)
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  }
}
</script>

<style scoped lang='scss'>
* {

  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.center {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.showBtn{
  width: 8.75rem;
  height: 2.5rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 2.125rem;
  border: 0;
  line-height: 2.5rem;
  font-size: .875rem;
  color: #fff;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
}

.moNewTextContainer{
  width: 100%;
  box-sizing: border-box;
  padding: 2.75rem 10px;
  overflow: hidden;
}
.moNewsContent{
  width: 100%;
  &::v-deep {
    p{
      width:100% !important;
      margin: 0px !important;
    }
     img{
      width:100% !important;
      height:100% !important;
    }
  }

}
</style>
